import { useEffect, useState } from 'react';
import configData from '../config.json';

import Tail from "../components/Tail";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';


function RegisterPage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [accountType, setAccountType] = useState('mobile');

  const [username, setUsername] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [mobile, setMobile] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleUsernameChange = (event) => {
    let name = event.target.value;
    name = name.trim();
    setUsername(name);
  }

  const handlePassword1Change = (event) => {
    let password = event.target.value;
    setPassword1(password.trim());
  }

  const handlePassword2Change = (event) => {
    let password = event.target.value;
    setPassword2(password.trim());
  }

  const handleMobileSubmit = (event) => {
    event.preventDefault();

    const regex = /^\+\d{11,}$/;
    if (!regex.test(mobile)) {
      alert('Please enter a valid phone number.');
      return;
    }

    const requestData = {
      username: mobile,
      password: '',
      source: 'MathTutor',
    };

    fetch(configData.API_URL + '/user/register/', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          alert(data.message);
          // jump to main page
          window.location.replace('/login');
        } else {
          alert(data.message);
        }
      })
      .catch(error => console.log(error));
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password1 !== password2) {
      alert('The passwords entered twice do not match!');
      return;
    }

    const re = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    let name = username.trim();
    if (!re.test(name)) {
      alert('Please enter a valid email address.');
      return;
    }

    const requestData = {
      username: name,
      password: password1.trim(),
      source: 'MathTutor',
    };

    fetch(configData.API_URL + '/user/register/', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          alert(data.message);
          // jump to main page
          window.location.replace('/login');
        } else {
          alert(data.message);
        }
      })
      .catch(error => console.log(error));
  }

  return (
    <div className='main-container'>

      <div className='background'>

        {windowWidth > 576 && (
          <div className='welcome-desc'>
            <h2>Register for a Mangosteen tutor account</h2>
            <br />
            <h4> &nbsp; Your exclusive personal tutor </h4>
            <br />
            <h5> &nbsp; - Solve any questions you have </h5>
            <h5> &nbsp; - Any time, get rid of the constraints of time </h5>
            <h5> &nbsp; - Supplement your learning blind spots </h5>
          </div>
        )}

        <div className="login">

          <ButtonGroup>
            <ToggleButton
              key="btn-1"
              id="btn-mobile"
              type="radio"
              variant={accountType === 'mobile' ? 'success' : 'outline-success'}
              name="radio"
              value="mobile"
              checked={accountType === 'mobile'}
              onChange={(e) => setAccountType(e.currentTarget.value)}
            >
              Mobile
            </ToggleButton>
            <ToggleButton
              key="btn-2"
              id="btn-email"
              type="radio"
              variant={accountType === 'email' ? 'success' : 'outline-success'}
              name="radio"
              value="email"
              checked={accountType === 'email'}
              onChange={(e) => setAccountType(e.currentTarget.value)}
            >
              E-mail
            </ToggleButton>
          </ButtonGroup>

          {accountType === 'email' ? (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className='login-label'>Email</Form.Label>
                <Form.Control type="text" placeholder="Enter your email" value={username} onChange={handleUsernameChange} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className='login-label'>Password</Form.Label>
                <Form.Control type="password" placeholder="Enter password" id='input-password1' value={password1} onChange={handlePassword1Change} />
                <p></p>
                <Form.Control type="password" placeholder="Password again" id='input-password2' value={password2} onChange={handlePassword2Change} />
              </Form.Group>

              <Button variant="success" type="submit" >Register</Button>
            </Form>
          ) : (
            <Form onSubmit={handleMobileSubmit}>
              <p></p>
              <p></p>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className='login-label'>Mobile</Form.Label>
                {/* <Form.Control type="text" placeholder="Input your mobile" value={username} onChange={handleUsernameChange} /> */}
                <PhoneInput
                  placeholder="Enter phone number"
                  value={mobile}
                  onChange={setMobile} />
              </Form.Group>
              <p></p>
              <p></p>
              <p></p>
              <p></p>
              <Button variant="outline-success" type="submit" >Register</Button>
            </Form>
          )}

        </div>
      </div>

      <Tail />
    </div>
  );
}

export default RegisterPage;
